import { Link, graphql } from 'gatsby';
import {
  capitalizeFirstLetter,
  formatPostDate,
  formatReadingTime,
} from '../utils/helpers';

import Bio from '../components/Bio';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Panel from '../components/Panel';
import React from 'react';
import SEO from '../components/SEO';
import get from 'lodash/get';
import { rhythm } from '../utils/typography';
import { languageTexts } from '../utils/languageTexts';

class BlogIndexTemplate extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const langKey = this.props.pageContext.langKey;
    const category = this.props.pageContext.category;

    const posts = get(this, 'props.data.allMarkdownRemark.edges');
    const { currentPage, numPages } = this.props.pageContext;

    const urlString = languageTexts[langKey]['url_category'] + '/' + category;
    const pageTitle =
      capitalizeFirstLetter(languageTexts[langKey]['url_category']) +
      ': ' +
      capitalizeFirstLetter(category);

    return (
      <Layout location={this.props.location} title={siteTitle} lang={langKey}>
        <SEO lang={langKey} title={pageTitle} />
        <aside>
          <Bio langKey={langKey} />
        </aside>
        <main>
          <Panel>{pageTitle}</Panel>

          {posts &&
            posts.map(({ node }) => {
              const title =
                get(node, 'frontmatter.title') || node.frontmatter.slug;

              let slug =
                node.frontmatter.slug !== null
                  ? `/` + node.frontmatter.slug
                  : node.fields.slug;

              return (
                <article key={node.fields.slug}>
                  <header>
                    <h3
                      style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: rhythm(1),
                        marginBottom: rhythm(1 / 4),
                      }}
                    >
                      <Link
                        style={{ boxShadow: 'none' }}
                        to={slug}
                        rel="bookmark"
                      >
                        {title}
                      </Link>
                    </h3>
                    <small>
                      {formatPostDate(node.frontmatter.date, langKey)}
                      {` • ${formatReadingTime(node.timeToRead, langKey)}`}

                      {` • 🏷 `}
                      {node.frontmatter.categories
                        ? node.frontmatter.categories.map(
                            (category, i, arr) => (
                              <Link
                                key={category}
                                to={`/${languageTexts[langKey]['url_category']}/${category}/`}
                                style={{
                                  fontSize: '12px',
                                  boxShadow: 'none',
                                }}
                              >
                                {category}
                                {arr.length - 1 !== i ? ', ' : null}
                              </Link>
                            )
                          )
                        : null}
                    </small>
                  </header>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.spoiler,
                    }}
                    style={{ textAlign: 'justify' }}
                  />
                </article>
              );
            })}

          {Array.from({ length: numPages }, (_, i) => (
            <Link
              key={`pagination-number${i + 1}`}
              to={`/${urlString}${i === 0 ? '' : '/p'}/${i === 0 ? '' : i + 1}`}
              style={{
                padding: rhythm(1 / 3),
                textDecoration: 'none',
                boxShadow: 'none',
                color: i + 1 === currentPage ? '#ffffff' : '',
                background: i + 1 === currentPage ? '#d23669' : '',
                border: '1px solid #d9d9d9',
                borderRadius: '6px',
                marginRight: '8px',
              }}
            >
              {i + 1}
            </Link>
          ))}
        </main>
        <Footer />
      </Layout>
    );
  }
}

export default BlogIndexTemplate;

export const pageQuery = graphql`
  query($langKey: String!, $category: String!, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { langKey: { eq: $langKey } }
        frontmatter: { categories: { glob: $category } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            langKey
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
            categories
            tags
            slug
          }
        }
      }
    }
  }
`;
